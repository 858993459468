import React from "react";

export const Services = (props) => {
  return (
    <div id="services" className="text-center">
      <div className="container">
        <div className="section-title">
          <h2>Our Services</h2>
          <p>We provide best-in-class engineering services.</p>
        </div>
        <div className="row">
          {props.data
            ? props.data.map((d, i) => (
                <div key={`${d.name}-${i}`} className="col-md-4">
                  {" "}
                  {/* <i className={d.icon}></i> */}
                  <div className="service-desc">
                    <h3>{d.name}</h3>
                    <p>{d.text}</p>
                  </div>
                </div>
              ))
            : "loading"}
        </div>
        <div className="expertise-list">
          <h3>Our Expertise Covers</h3>
          <ul>
            <li>Power Piping B31.1</li>
            <li>Process Piping B31.3</li>
            <li>Refrigeration Piping B31.5</li>
            <li>Medical Gas Piping CSA Z7396.1</li>
            <li>High and Low-Pressure Boiler Manufacturers</li>
            <li>Pressure Vessels Manufacturers – Division 1 & 2</li>
            <li>Repair and Alteration Organizations</li>
            <li>Category A, B, C, D, E, F & H Type Fittings</li>
          </ul>
        </div>
      </div>
    </div>
  );
};
